import { useInvestor } from "../../hooks/vesting.hooks";
import styles from "../../styles/Claim/ClaimBox.module.scss";
import Form from "./Form";

export default function ClaimBox({ investor }: { investor: ReturnType<typeof useInvestor> }): JSX.Element {

    return (
        <section className={styles.exchange}>
            <div className={styles.form}>
                <div className={styles.form__bg_1}>
                    <div className={styles.form__bg_2}>
                        <div className={styles.form__bg_3}>
                            <Form investor={investor} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
