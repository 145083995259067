import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, Area, ComposedChart } from 'recharts';
import { useInvestor } from '../../hooks/vesting.hooks';

const VestingChart = () => {
    const [data, setData] = useState<any[]>([]);
    const [currentTime, setCurrentTime] = useState((new Date()).getTime() / 1000);

    const { vestingStartTime, allocation, withdrawn } = useInvestor();

    useEffect(() => {
        const generateChartData = () => {
            const now = Math.floor(((new Date()).getTime()) / 1000);
            const vestingBucketDuration = process.env.REACT_APP_ENV === "production" ? 604800 : 900;
            const totalBuckets = Math.ceil((100 - 50) / 10); // Total buckets to reach 100% allocation
            const chartData = [];

            for (let i = 0; i <= totalBuckets; i++) {
                const weeksSinceStart = i;
                const bucketTime = vestingStartTime + (weeksSinceStart * vestingBucketDuration);

                let vestedAmount;
                if (weeksSinceStart === 0) {
                    vestedAmount = (allocation * 0.5);
                } else {
                    vestedAmount = (allocation * (50 + (weeksSinceStart * 10)) / 100);
                }

                if (vestedAmount > allocation) {
                    vestedAmount = allocation;
                }

                if (vestingStartTime > 0)
                    chartData.push({
                        time: new Date(bucketTime * 1000).toLocaleDateString().slice(0, -5),
                        vestedAmount,
                        vestedAmountArea: vestedAmount
                    });
                else
                    chartData.push({
                        time: "",
                        vestedAmount: 0,
                        vestedAmountArea: 0
                    });
            }

            setData(chartData);
            setCurrentTime(now);
        };

        generateChartData();
    }, [vestingStartTime, allocation, withdrawn]);

    return (
        <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                data={data}
            >
                <defs>
                    <linearGradient id="colorVestedAmount" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#63C796" stopOpacity={0.2} />
                        <stop offset="95%" stopColor="#63C796" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} strokeDasharray="10" style={{ "opacity": "0.3" }} />
                <XAxis dataKey="time" />
                {/* <YAxis /> */}
                <Tooltip contentStyle={{
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    border: "none",
                    borderRadius: "10px"
                }} />
                <Area
                    type="monotone"
                    dataKey="vestedAmount"
                    stroke="#63C796"
                    fillOpacity={1}
                    strokeWidth={1}
                    fill="url(#colorVestedAmount)"
                    tooltipType='none' />
                <Line
                    type="monotone"
                    dataKey="vestedAmount"
                    stroke="#63C796"
                    activeDot={{ r: 8 }} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default VestingChart;
