import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetEstimatedAmount, useTransaction } from "../../hooks";
import styles from "../../styles/Exchange/Exchange.module.scss";
import { ExchangeFees, TransactionResponse } from "../../types";
import Fees from "./Fees";
import Finished from "./Finished";
import Form from "./Form";
import Pending from "./Pending";
import Steps from "./Steps";

// import { ReactComponent as UpperEllipse } from "../../assets/images/upper_ellipse.svg";
// import { ReactComponent as LowerEllipse } from "../../assets/images/lower_ellipse.svg";

export default function Exchange(): JSX.Element {
    const [fees, setFees] = useState<ExchangeFees>({
        withdrawalFee: 0,
        depositFee: 0,
        fromAmount: 0,
        toAmount: 0,
        fromCurrency: "",
        toCurrency: "",
        fromTicker: "",
        toTicker: "",
    });
    const [step, setStep] = useState<number>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const txStatusQuery = useTransaction(id || "");
    const { data, isLoading, isError } = txStatusQuery;
    const estimationQuery = useGetEstimatedAmount(
        {
            fromCurrency: data?.data.fromCurrency || "",
            toCurrency: data?.data.toCurrency || "",
            fromAmount:
                data?.data.amountFrom || data?.data.expectedAmountFrom || 0,
            toAmount: data?.data.amountTo || data?.data.expectedAmountTo || 0,
            fromNetwork: data?.data.fromNetwork || "",
            toNetwork: data?.data.toNetwork || "",
        },
        false
    );

    function component(data: AxiosResponse<TransactionResponse> | undefined) {
        if (
            data?.data.status &&
            ["waiting", "confirming", "exchanging", "sending"].includes(
                data?.data.status
            )
        ) {
            return <Pending transaction={txStatusQuery} />;
        }
        if (data?.data.status === "finished") {
            return (
                <div className={styles.form__bg_1}>
                    <div className={styles.form__bg_2}>
                        <div className={styles.form__bg_3}>
                            <Finished transaction={txStatusQuery} fees={fees} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.form__bg_1}>
                <div className={styles.form__bg_2}>
                    <div className={styles.form__bg_3}>
                        <Form
                            transaction={txStatusQuery}
                            setStep={setStep}
                            setFees={setFees}
                        />
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (isError) {
            toast.error("Invalid transaction ID");
            setSearchParams({ id: "" });
            setStep(1);
        }
    }, [isError]);

    useEffect(() => {
        if (
            data?.data.status &&
            ["waiting", "confirming", "exchanging", "sending"].includes(
                data?.data.status
            )
        ) {
            setStep(3);
        } else if (data?.data.status === "finished") {
            setStep(4);
        }
        if (data?.data) estimationQuery.refetch();
    }, [data]);

    useEffect(() => {
        if (
            id &&
            !estimationQuery.isError &&
            estimationQuery.data?.data &&
            step > 2
        ) {
            setFees({
                ...estimationQuery.data?.data,
                toAmount:
                    txStatusQuery.data?.data.amountTo ||
                    txStatusQuery.data?.data.expectedAmountTo ||
                    0,
                fromAmount:
                    txStatusQuery.data?.data.amountFrom ||
                    txStatusQuery.data?.data.expectedAmountFrom ||
                    0,
                toTicker: "",
                fromTicker: "",
            });
        }
    }, [estimationQuery.data]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <section className={styles.exchange}>
            <div className={styles.steps}>
                <Steps step={step} />
            </div>
            <div className={styles.form}>
                {component(data)}
                {/* <UpperEllipse className={styles.upperEllipse} />
                <LowerEllipse className={styles.lowerEllipse} /> */}
            </div>
            <div className={styles.fees}>
                <Fees fees={fees} isLoading={estimationQuery.isLoading} />
            </div>
        </section>
    );
}
