import axios from "axios";
import { CreateTransactionRequest, CreateTransactionResponse, CurrenciesData, EstimateAmountData, EstimateAmountParams, MinAmountData, MinAmountParams, TransactionResponse } from "../types";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000",
});

export const getAvailablePairs = async () => api.get<CurrenciesData[]>("/payments/pairs");
export const getMinAmount = async (params: MinAmountParams) => api.get<MinAmountData>("/payments/min-amount", { params });
export const getEstimatedAmount = async (params: EstimateAmountParams) => api.get<EstimateAmountData>("/payments/estimated-amount", { params });
export const createTransaction = async (data: CreateTransactionRequest) => api.post<CreateTransactionResponse>("/payments/create-transaction", data);
export const getTransaction = async (id: string) => api.get<TransactionResponse>(`/payments/transaction/${id}`);

export default api;
