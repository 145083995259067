import { useState, useEffect } from "react";
import { useSwitchChain, useAccount } from "wagmi";
import { mainnet } from "wagmi/chains";

const chainId = mainnet.id;

const useCheckChain = () => {
    const [isCorrectChain, setIsCorrectChain] = useState(false);
    const [isSwitching, setIsSwitching] = useState(false);

    const { switchChainAsync } = useSwitchChain();
    const { chainId: currentChainId } = useAccount();

    useEffect(() => {
        setIsCorrectChain(currentChainId == chainId);
    }, [currentChainId]);

    const switchToCorrectChain = async () => {
        try {
            setIsSwitching(true);
            await switchChainAsync({ chainId });
        } catch (error) {
            console.error(error);
        } finally {
            setIsSwitching(false);
        }
    };

    return { isCorrectChain, isSwitching, switchToCorrectChain };
};

export { useCheckChain };