import { usePrice } from "../hooks/chainlink";
import Marquee from "react-fast-marquee";
import styles from "../styles/Prices.module.scss";
import { ethLogo, solLogo, avaxLogo, bnbLogo, btcLogo, trxLogo, opLogo } from "../assets/images";

export default function Prices(): JSX.Element {
    const { ethPrice, solPrice, avaxPrice, bnbPrice, btcPrice, opPrice, trxPrice } = usePrice();

    return (
        <div className={styles.main}>
            <Marquee
                className={styles.inner}
                autoFill
                gradient
                gradientColor={"#03031B"}
                gradientWidth={"50px"}>
                {/* {ethPrice && <div className={styles.curr}>
                    <img src={ethLogo} alt="" />
                    <span>${ethPrice.toLocaleString()}</span>
                </div>} */}
                {solPrice && <div className={styles.curr}>
                    <img src={solLogo} alt="" />
                    <span>${solPrice.toLocaleString()}</span>
                </div>}
                {avaxPrice && <div className={styles.curr}>
                    <img src={avaxLogo} alt="" />
                    <span>${avaxPrice.toLocaleString()}</span>
                </div>}
                {bnbPrice && <div className={styles.curr}>
                    <img src={bnbLogo} alt="" />
                    <span>${bnbPrice.toLocaleString()}</span>
                </div>}
                {btcPrice && <div className={styles.curr}>
                    <img src={btcLogo} alt="" />
                    <span>${btcPrice.toLocaleString()}</span>
                </div>}
                {opPrice && <div className={styles.curr}>
                    <img src={opLogo} alt="" />
                    <span>${opPrice.toLocaleString()}</span>
                </div>}
                {trxPrice && <div className={styles.curr}>
                    <img src={trxLogo} alt="" />
                    <span>${trxPrice.toLocaleString()}</span>
                </div>}
            </Marquee>
        </div>
    );
}
