import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { vestingContracts as contracts } from "../configs/contracts.config";
import vestingAbi from "../configs/abi/VestingContract.json";
import { mainnet } from "wagmi/chains";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const chainId = mainnet.id;

const useVestingStartTime = () => {
    const { data, ...readData } = useReadContract({
        abi: vestingAbi,
        address: contracts[chainId].VestingContract as `0x${string}`,
        functionName: "vestingStartTime"
    });

    const returnedData = data as bigint;
    return {
        vestingStartTime: Number(returnedData) || 0,
        ...readData
    };
};

const useAllocation = () => {
    const { address } = useAccount();
    const { data, ...readData } = useReadContract({
        abi: vestingAbi,
        address: contracts[chainId].VestingContract as `0x${string}`,
        args: [address],
        functionName: "checkAllocation"
    });
    const returnedData = data as [bigint, bigint];
    return {
        allocation: returnedData ? Number(returnedData[0] / BigInt(10 ** 18)) : 0,
        withdrawn: returnedData ? Number(returnedData[1] / BigInt(10 ** 18)) : 0,
        ...readData
    };
};

const useInvestor = () => {
    const [claimable, setClaimable] = useState(0);
    const [claimableETA, setClaimableETA] = useState<null | Date>(null);
    const { allocation, withdrawn, refetch: refetchAllocation, isRefetching: isAllocationRefetching } = useAllocation();
    const { vestingStartTime } = useVestingStartTime();

    useEffect(() => {
        calculate();
    }, [vestingStartTime, allocation, withdrawn]);

    const calculate = () => {
        if (vestingStartTime == 0 ||
            allocation == 0 ||
            (allocation - withdrawn) == 0) {
            setClaimable(0);
        } else {
            const now = Math.floor(((new Date()).getTime()) / 1000);
            const timeSinceStart = now - vestingStartTime;
            const vestingBucketDuration = process.env.REACT_APP_ENV == "production" ? 604800 : 900;
            const bucketsSinceStart = Math.floor(timeSinceStart / vestingBucketDuration);

            let _claimable;

            _claimable = bucketsSinceStart == 0
                ? (allocation * 0.5) - withdrawn
                : (allocation * (50 + (bucketsSinceStart * 10)) / 100) - withdrawn;

            if (_claimable + withdrawn > allocation) {
                _claimable = allocation - withdrawn;
            }

            setClaimable(_claimable);

            if (_claimable + withdrawn < allocation) {
                const timeToNextBucket = vestingBucketDuration - (timeSinceStart % vestingBucketDuration);
                const etaDate = new Date(Number((now + timeToNextBucket) * 1000));
                setClaimableETA(etaDate);
            }
        }
    }

    const refetch = async () => {
        await refetchAllocation();
    }

    return { allocation, withdrawn, claimable, claimableETA, vestingStartTime, refetch, isAllocationRefetching, calculate };
};

const useClaim = () => {
    const { writeContractAsync, ...states } = useWriteContract();
    const [txHash, setTxHash] = useState<`0x${string}`>();

    const claim = async () => {
        try {
            const tx = await writeContractAsync({
                abi: vestingAbi,
                address: contracts[chainId].VestingContract as `0x${string}`,
                functionName: 'withdrawTokens',
            });
            setTxHash(tx);
        } catch (err) {
            console.error(err);
            toast.error("Failed to claim!");
        }
    }

    return { claim, txHash, ...states };
}

export { useVestingStartTime, useAllocation, useInvestor, useClaim };