import { AxiosResponse } from "axios";
import { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import dotted_arrow_icon from "../../assets/images/dotted_arrow_icon.png";
import round_arrow_icon from "../../assets/images/round_arrow_icon.png";
import round_tick_icon from "../../assets/images/round_tick_icon.png";
import styles from "../../styles/Exchange/Finished.module.scss";
import { ExchangeFees, TransactionResponse } from "../../types";

export default function Finished({
    fees,
    transaction,
}: {
    fees: ExchangeFees;
    transaction: UseQueryResult<AxiosResponse<TransactionResponse>, unknown>;
}): JSX.Element {
    const { data, isLoading, isError } = transaction;
    const { withdrawalFee, depositFee } = fees;

    const timeTaken = useMemo(() => {
        if (!data?.data) return 0;
        const createdAt = new Date(data?.data.createdAt || "");
        const updatedAt = new Date(data?.data.updatedAt || "");
        const timeTaken = updatedAt.getTime() - createdAt.getTime();
        const minutes = timeTaken / 60000;
        return minutes.toFixed(2);
    }, [data?.data]);

    return (
        <div className={styles.transaction}>
            {isLoading ? (
                <p>Loading...</p>
            ) : isError ? (
                <p>Error</p>
            ) : (
                <>
                    <img
                        src={round_tick_icon}
                        alt="Tick"
                        className={styles.tick__sign}
                    />
                    <h1>Finished</h1>
                    <p className={styles.time__taken}>
                        Your exchange was completed in {timeTaken} mins!
                    </p>
                    <div className={styles.exchanged__assets}>
                        <div className={styles.asset}>
                            <img src="" alt="" className={styles.asset__icon} />
                            <p className={styles.asset__value}>
                                {data?.data.amountFrom}{" "}
                                {data?.data.fromCurrency.toUpperCase()}
                            </p>
                        </div>
                        <img
                            src={dotted_arrow_icon}
                            alt="Arrow"
                            className={styles.asset__arrow}
                        />
                        <div className={styles.asset}>
                            <img src="" alt="" className={styles.asset__icon} />
                            <p className={styles.asset__value}>
                                {data?.data.amountTo}{" "}
                                {data?.data.toCurrency.toUpperCase()}
                            </p>
                        </div>
                    </div>
                    <div className={styles.fees}>
                        <div className={styles.fee__row}>
                            <p className={styles.title}>Deposit Fee</p>
                            <p className={styles.value}>
                                ~ {depositFee ? depositFee : "..."}{" "}
                                {data?.data.fromCurrency}
                            </p>
                        </div>
                        <hr />
                        <div className={styles.fee__row}>
                            <p className={styles.title}>Withdraw Fee</p>
                            <p className={styles.value}>
                                ~ {withdrawalFee ? withdrawalFee : "..."}{" "}
                                {data?.data.toCurrency}
                            </p>
                        </div>
                    </div>
                    <a href="/" className={styles.new__exchange}>
                        <img
                            src={round_arrow_icon}
                            alt="Icon"
                            className={styles.icon}
                        />
                        <p>Start new exchange</p>
                    </a>
                </>
            )}
        </div>
    );
}
