import styles from "../styles/Sidebar.module.scss";
import zkgun_logo_compact from "../assets/images/logo-compact.svg";
import logoText from "../assets/images/logo-text.svg";
import swap from "../assets/images/swap.svg";
import claim from "../assets/images/claim.svg";
import stake from "../assets/images/stake.svg";
import messaging from "../assets/images/messaging.svg";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";
import document from "../assets/images/document.svg";
import hamburger_icon from "../assets/images/hamburger_icon.png";
import zkgun_logo from "../assets/images/zkgun_logo.png";
import wallet from "../assets/images/wallet.svg";
import pair_analytics from "../assets/images/pair_analytics.svg";
import trade_analytics from "../assets/images/trade_analytics.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const SideNav = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const pathName = location.pathname;

    const swapPage = pathName.includes("swap");
    const stakePage = pathName.includes("stake");
    const claimPage = pathName.includes("claim");

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.main}>
            <div className={styles.mobileNav}>
                <div className={`${styles.logoContainer}`}>
                    <a href="/" className={styles.logoLink}>
                        <img
                            src={zkgun_logo}
                            alt="zkgun-logo"
                            className={styles.logoImage}
                        />
                    </a>
                </div>
                <button className={styles.mobileMenu} onClick={toggleMenu}>
                    {!isMenuOpen ? (
                        <img src={hamburger_icon} alt="menu-icon" />
                    ) : (
                        "X"
                    )}
                </button>
            </div>
            <div className={`${styles.mobileOpenOverlay} ${isMenuOpen ? styles.mobileOpen : ""}`} onClick={toggleMenu} />
            <div className={`${styles.sidebar} ${isMenuOpen ? styles.mobileOpen : ""}`}>
                <div className={styles.firstRow}>
                    <div
                        className={styles.sideContentWrapper + " " + styles.logoWrapper}
                        role="button"
                        onClick={() => { navigate("/"); toggleMenu(); }}>
                        <img src={zkgun_logo_compact} className={styles.sideIcon} alt="compact-logo" />
                        <img src={logoText} className={styles.logoText} alt="ZKGUN" />
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${(swapPage || pathName === "/") && styles.active}`}
                        role="button"
                        onClick={() => { navigate("/swap"); toggleMenu(); }}>
                        <img src={swap} className={styles.sideIcon} alt="swap" />
                        <span>Swap</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${claimPage && styles.active}`}
                        role="button"
                        onClick={() => { navigate("/claim"); toggleMenu(); }}>
                        <img src={claim} className={styles.sideIcon} alt="claim" />
                        <span>Claim</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/stake"); toggleMenu(); }}>
                        <img src={stake} className={styles.sideIcon} alt="stake" />
                        <span>Stake</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/wallet"); toggleMenu(); }}>
                        <img src={wallet} className={styles.sideIcon} alt="wallet" />
                        <span>Wallet</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/pair-analytics"); toggleMenu(); }}>
                        <img src={pair_analytics} className={styles.sideIcon} alt="pair analytics" />
                        <span>Pair Analytics</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/trade-analytics"); toggleMenu(); }}>
                        <img src={trade_analytics} className={styles.sideIcon} alt="trade analytics" />
                        <span>Trade Analytics</span>
                    </div>
                </div>
                <div className={styles.secondRow}>
                    <div className={styles.sideContentWrapper} onClick={() => { window.open('https://t.me/zkgunbot', '_blank'); }}>
                        <img src={messaging} className={styles.sideIcon} alt="messaging" />
                        <span>TG Bot</span>
                    </div>
                    <div className={styles.sideContentWrapper} onClick={() => { window.open('https://t.me/zkgunproject', '_blank'); }}>
                        <img src={telegram} className={styles.sideIcon} alt="telegram" />
                        <span>Commmunity</span>
                    </div>

                    <div className={styles.sideContentWrapper} onClick={() => { window.open('https://x.com/zkgunproject', '_blank'); }}>
                        <img src={x} className={styles.sideIcon} alt="twitter" />
                        <span>Twitter</span>
                    </div>

                    <div className={styles.sideContentWrapper} onClick={() => { window.open('https://docs.zkgun.org', '_blank'); }}>
                        <img src={document} className={styles.sideIcon} alt="document" />
                        <span>Docs</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
