import { useMutation, useQuery } from "@tanstack/react-query";
import { createTransaction, getAvailablePairs, getEstimatedAmount, getMinAmount, getTransaction } from "../api";
import { EstimateAmountParams, MinAmountParams } from "../types";

export function useGetAvailablePairs() {
    return useQuery({
        queryKey: ["availablePairs"],
        queryFn: getAvailablePairs,
        staleTime: 1000 * 60 * 60 * 1,
        refetchOnWindowFocus: false,
    });
}

export function useGetMinAmount(params: MinAmountParams, enabled?: boolean) {
    return useQuery({
        queryKey: ["minAmount", params],
        queryFn: () => getMinAmount(params),
        refetchOnWindowFocus: false,
        enabled: false || enabled || (!!params.fromCurrency && !!params.toCurrency && !!params.fromNetwork && !!params.toNetwork),
    });
}

export function useGetEstimatedAmount(params: EstimateAmountParams, enabled?: boolean) {
    return useQuery({
        queryKey: ["estimatedAmount", params],
        queryFn: () => getEstimatedAmount(params),
        refetchOnWindowFocus: false,
        enabled: false || enabled,
        retry: false,
    });
}

export function useTransaction(id: string) {
    return useQuery({
        queryKey: ["transaction", id],
        queryFn: () => getTransaction(id),
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 30, // 30 seconds
        enabled: !!id,
        retry: false,
    });
}

export function useCreateTransaction() {
    return useMutation({mutationFn: createTransaction});
}