import { useEffect, useRef, useState } from "react";
import styles from "../styles/SearchableDropdown.module.scss";
import { IoMdClose } from "react-icons/io";

type IOption = {
    [key: string]: string | boolean | undefined;
    image?: string;
    disabled: boolean;
};

export default function SearchableDropdown({
    options,
    defaultToken,
    label,
    id,
    selectedVal,
    handleChange,
}: {
    options: IOption[];
    defaultToken?: string;
    label: string;
    id: string;
    selectedVal: string;
    handleChange: (value: string) => void;
}): JSX.Element {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const selectOption = (option: IOption) => {
        setQuery(() => "");
        handleChange(option[label] as string);
        setTimeout(() => setIsOpen(false), 200);
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal;

        return "";
    };

    const filter = (options: IOption[]) => {
        const result = options.filter(
            (option) =>
                (option[label] as string)
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
        );
        if (result.length === 0) {
            return [
                {
                    [label]: "No results found",
                    disabled: true,
                },
            ];
        }
        return result;
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" || e.key === "Tab") {
                setIsOpen(false);
            }
        };

        const handleMouseUp = (e: MouseEvent | TouchEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(e.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mouseup", handleMouseUp);
            document.addEventListener("touchend", handleMouseUp);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchend", handleMouseUp);
        };
    }, [inputRef, isOpen]);

    useEffect(() => {
        if (defaultToken && !selectedVal)
            handleChange(defaultToken);
    }, [defaultToken, options]);

    return (
        <>
            {isOpen && <div className={styles.overlay} onClick={toggle}></div>}
            <div className={`${styles.dropdown}`}>
                <div
                    className={styles.control}
                    onClick={() => {
                        if (!isOpen) {
                            setIsOpen(true);
                        }
                    }}
                >
                    <div className={styles.selected__value}>
                        {getDisplayValue() || "..."}
                    </div>
                    <div
                        className={`${styles.arrow} ${isOpen ? styles.open : ""}`}
                        onClick={toggle}
                    ></div>
                </div>

            </div>
            <div
                className={`${styles.modal} ${isOpen ? styles.open : ""}`}
            >
                <div className={styles.header}>
                    <span>
                        Select Token
                    </span>
                    <IoMdClose onClick={toggle} />
                </div>
                <div className={`${styles.options}`}>
                    {filter(options).map((option, index) => {
                        return (
                            <div
                                onClick={() => selectOption(option)}
                                className={`${styles.option} ${option[label] === selectedVal
                                    ? styles.selected
                                    : ""}`}
                                key={`${id}-${index}`}
                                aria-disabled={option["disabled"]}
                            >
                                <img src={process.env.REACT_APP_API_URL + "image/" + option.image} alt="" />
                                {option[label]}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
