import { ethers } from "ethers";
import { useQuery } from "@tanstack/react-query";
import aggregatorV3Config from "../configs/abi/AggregatorV3.json";
import { chainlinkContracts } from "../configs/contracts.config";
import { useEffect, useState } from "react";
import { ChainlinkNetwork } from "../types";

const optimismProvider = new ethers.providers.JsonRpcProvider("https://mainnet.optimism.io");

const fetchData = async (address: string, functionName: string) => {
    const contract = new ethers.Contract(address, aggregatorV3Config.abi, optimismProvider);
    const data = await contract[functionName]();
    return data;
};

const usePriceData = (chain: ChainlinkNetwork) => {
    const address = chainlinkContracts[chain];
    const { data: priceData } = useQuery({
        queryKey: [chain, "priceData"],
        queryFn: () => fetchData(address, "latestRoundData")
    });
    const { data: decimalsData } = useQuery({
        queryKey: [chain, "decimalsData"],
        queryFn: () => fetchData(address, "decimals")
    });

    return { priceData, decimalsData };
};

const useEthPrice = () => {
    const [ethPrice, setEthPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Ethereum");

    useEffect(() => {
        if (priceData && decimalsData) {
            setEthPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { ethPrice };
};

const useSolPrice = () => {
    const [solPrice, setSolPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Solana");

    useEffect(() => {
        if (priceData && decimalsData) {
            setSolPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { solPrice };
};

const useBtcPrice = () => {
    const [btcPrice, setBtcPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Bitcoin");

    useEffect(() => {
        if (priceData && decimalsData) {
            setBtcPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { btcPrice };
};

const useAvaxPrice = () => {
    const [avaxPrice, setAvaxPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Avalanche");

    useEffect(() => {
        if (priceData && decimalsData) {
            setAvaxPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { avaxPrice };
};

const useArbPrice = () => {
    const [arbPrice, setArbPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Arbitrum");

    useEffect(() => {
        if (priceData && decimalsData) {
            setArbPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { arbPrice };
};

const useBnbPrice = () => {
    const [bnbPrice, setBnbPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("BNB Smart Chain");

    useEffect(() => {
        if (priceData && decimalsData) {
            setBnbPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { bnbPrice };
};

const useTrxPrice = () => {
    const [trxPrice, setTrxPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("TRON");

    useEffect(() => {
        if (priceData && decimalsData) {
            setTrxPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { trxPrice };
};

const useOpPrice = () => {
    const [opPrice, setOpPrice] = useState<null | number>(null);
    const { priceData, decimalsData } = usePriceData("Optimism");

    useEffect(() => {
        if (priceData && decimalsData) {
            setOpPrice(Number((priceData[1].toString() / Math.pow(10, decimalsData)).toFixed(2)));
        }
    }, [priceData, decimalsData]);

    return { opPrice };
};

const usePrice = () => {
    const { ethPrice } = useEthPrice();
    const { solPrice } = useSolPrice();
    const { btcPrice } = useBtcPrice();
    const { avaxPrice } = useAvaxPrice();
    const { arbPrice } = useArbPrice();
    const { bnbPrice } = useBnbPrice();
    const { trxPrice } = useTrxPrice();
    const { opPrice } = useOpPrice();

    return {
        ethPrice,
        solPrice,
        btcPrice,
        avaxPrice,
        arbPrice,
        bnbPrice,
        trxPrice,
        opPrice,
    };
};

export {
    useEthPrice,
    useSolPrice,
    useBtcPrice,
    useAvaxPrice,
    useArbPrice,
    useBnbPrice,
    useTrxPrice,
    useOpPrice,
    usePrice
};
