import { useAccount } from "wagmi";
import { useInvestor } from "../../hooks/vesting.hooks";
import styles from "../../styles/Claim/Claim.module.scss";
import ClaimBox from "./ClaimBox";
import { useCheckChain } from "../../hooks/web3.hooks";

export default function Claim(): JSX.Element {
    const { isConnected } = useAccount();
    const investor = useInvestor();
    const { isCorrectChain } = useCheckChain();

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                {
                    !isConnected
                        ? <><span>Connect wallet</span> to check your allocation! </>
                        : !isCorrectChain
                            ? <>Incorrect network, <span>switch network</span> to continue!</>
                            : <>
                                {(investor.claimable > 0) &&
                                    <>
                                        Congratulations! <br />
                                        You have <span>{(investor.claimable).toLocaleString()} $ZKGUN</span>  to claim!
                                    </>}
                                {(investor.allocation > 0 && investor.claimable == 0 && investor.withdrawn < investor.allocation) &&
                                    <>
                                        {
                                            investor.claimableETA
                                                ? <>Nothing to claim, try again on <br /> <span>{investor.claimableETA.toDateString()}</span></>
                                                : <>Nothing to claim, <span>yet ;)</span></>
                                        }
                                    </>}
                                {(investor.allocation == 0) &&
                                    <>
                                        <span>Nothing</span> to claim :(
                                    </>}
                                {(investor.allocation > 0 && investor.withdrawn >= investor.allocation) &&
                                    <>
                                        You've <span>claimed all</span> your tokens!
                                    </>}
                            </>
                }
            </div>
            {/* @fix - avoid prop drilling, create context for these hooks */}
            <ClaimBox investor={investor} />
        </div>
    );
}
