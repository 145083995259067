import { AxiosResponse } from "axios";
import QRCode from "react-qr-code";
import { UseQueryResult } from "@tanstack/react-query";
import { toast } from "react-toastify";
import copy_icon from "../../assets/images/copy_icon.png";
import styles from "../../styles/Exchange/Pending.module.scss";
import { TransactionResponse } from "../../types";
import Loader from "../Loader";
import { useSearchParams } from "react-router-dom";

export default function Pending({
    transaction,
}: {
    transaction: UseQueryResult<AxiosResponse<TransactionResponse>, unknown>;
}): JSX.Element {
    const { data, isLoading } = transaction;

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    function handleCopy(text: string) {
        navigator.clipboard.writeText(text);
        toast.info("Copied to clipboard");
    }

    return (
        <>
            <div className={styles.instruction__box}>
                <div className={styles.status}>
                    <Loader className={styles.loader} />
                    <p>Transaction in progress</p>
                </div>
                <div className={styles.txIdContainer}>
                    <div className={styles.transactionId}>
                        <div className={styles.title}>ID:</div>
                        <div className={styles.vr} />
                        <div className={styles.txId}>{id}</div>
                        <div className={styles.vr} />
                        <img
                            className={styles.txCopy}
                            alt="copy"
                            src={copy_icon}
                            onClick={() =>
                                handleCopy(id || "")
                            }
                        />
                    </div>
                </div>
                <p className={styles.title}>Awaiting Deposit. Please send your deposit to the following address:</p>
                <div className={styles.hr} />
                <div className={styles.qr_container}>
                    <div className={styles.qr_code}>
                        {isLoading ? (
                            "Loading..."
                        ) : (
                            <QRCode
                                value={data?.data.payinAddress || ""}
                                size={150}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.hr} />
                <div className={styles.column}>
                    <div className={styles.value__box}>
                        <p className={styles.label}>Amount:</p>
                        <div className={styles.value}>
                            {isLoading
                                ? "Loading..."
                                : `${data?.data.expectedAmountFrom || ""} ${(
                                    data?.data.fromCurrency || ""
                                ).toUpperCase()}`}
                        </div>
                    </div>
                    <div className={styles.value__box}>
                        <p className={styles.label}>Address:</p>
                        <div className={styles.value}>
                            {isLoading ? (
                                "Loading..."
                            ) : (
                                <>
                                    <p>{data?.data.payinAddress || ""}</p>
                                    <img
                                        className={styles.address__copy}
                                        alt="copy"
                                        src={copy_icon}
                                        onClick={() =>
                                            handleCopy(
                                                data?.data.payinAddress || ""
                                            )
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    {data?.data.payinExtraId &&
                        <div className={styles.value__box}>
                            <p className={styles.label}>Extra Id:</p>
                            <div className={styles.value}>
                                {isLoading ? (
                                    "Loading..."
                                ) : (
                                    <>
                                        <p>{data?.data.payinExtraId || ""}</p>
                                        <img
                                            className={styles.address__copy}
                                            alt="copy"
                                            src={copy_icon}
                                            onClick={() =>
                                                handleCopy(
                                                    data?.data.payinExtraId || ""
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
