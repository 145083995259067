export const chainlinkContracts = {
    "Ethereum": "0xb7B9A39CC63f856b90B364911CC324dC46aC1770",
    "Solana": "0xC663315f7aF904fbbB0F785c32046dFA03e85270",
    "Bitcoin": "0xD702DD976Fb76Fffc2D3963D037dfDae5b04E593",
    "Avalanche": "0x5087Dc69Fd3907a016BD42B38022F7f024140727",
    "Arbitrum": "0x8f14546d0B960793180ee355B73fA55041a4a356",
    "BNB Smart Chain": "0xD38579f7cBD14c22cF1997575eA8eF7bfe62ca2c",
    "TRON": "0x0E09921cf7801A5aD47B892C8727593275625a9f",
    "Optimism": "0x0D276FC14719f9292D5C1eA2198673d1f4269246"
}

export const vestingContracts = {
    1: {
        "VestingContract": "0xbE1D2F8Ae6BEc6A33026A6d7B2e5eAF91Fb948bF"
    },
    11155111: {
        "VestingContract": "0x67C8Ac55B948BB113f3050E1cf3762Bf88bf56D5"
    }
}