import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Home } from "./pages";
import "./styles/global.scss";
import styles from "./styles/Pages/Home.module.scss";

import leftBg from "./assets/images/left_bg.png";
import rightBg from "./assets/images/right_bg.png";
import rightBgMobile from "./assets/images/right_bg_mobile.png";
import { SideNav } from "./components/SideNav";
import Claim from "./components/Claim";
import { Stake } from "./pages/Stake";

function App() {
	return (
		<>
			<SideNav />
			<div className={styles.routes}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/swap" element={<Home />} />
					<Route path="/claim" element={<Claim />} />
					<Route path="/stake" element={<Stake />} />
				</Routes>
			</div>
			<img src={leftBg} alt="bg" className={styles.leftBg} />
			<img src={rightBg} alt="bg" className={styles.rightBg} />
			<img src={rightBgMobile} alt="bg" className={styles.rightBgMobile} />
			<ToastContainer theme="dark" position="bottom-right" />
		</>
	);
}

export default App;
