import { useAccount, useWaitForTransactionReceipt } from "wagmi";
import styles from "../../styles/Claim/Form.module.scss";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useClaim, useInvestor } from "../../hooks/vesting.hooks";
import { useCheckChain } from "../../hooks/web3.hooks";
import { useEffect, useState } from "react";
import zkGunLogo1 from "../../assets/images/zkgun_logo_1.png";
import timerIcon from "../../assets/images/timer.png";
import VestingChart from "./VestingChart";
import { toast } from "react-toastify";

export default function Form({ investor }: { investor: ReturnType<typeof useInvestor> }): JSX.Element {
    const { isConnected, address, isConnecting, isReconnecting } = useAccount();
    const { isCorrectChain, isSwitching, switchToCorrectChain } = useCheckChain();
    const { open } = useWeb3Modal();
    const { allocation, withdrawn, claimable, refetch, calculate, claimableETA, isAllocationRefetching } = investor;
    const { claim, txHash, isPending } = useClaim();
    const { isFetching, isFetched, isSuccess } = useWaitForTransactionReceipt({ hash: txHash, confirmations: 2 });

    const [isLoading, setIsLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState('');

    useEffect(() => {
        if (isConnecting || isReconnecting || isSwitching || isPending || isFetching || isAllocationRefetching)
            setIsLoading(true);
        else
            setIsLoading(false);
    }, [isConnecting, isReconnecting, isSwitching, isPending, isFetching, isAllocationRefetching]);

    useEffect(() => {
        if (!isFetching)
            refetch();
        if (!isFetched && isFetched && isSuccess)
            toast.success("Claim successful!");
    }, [isFetching, isFetched, isSuccess]);


    useEffect(() => {
        const calculateTimeRemaining = async () => {
            if (!claimableETA) return;

            const now = new Date().getTime();
            const distance = claimableETA?.getTime() - now;

            if (distance < 0) {
                setTimeRemaining('00:00:00');
                calculate();
                return;
            }

            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            setTimeRemaining(formattedTime);
        };

        const timer = setInterval(calculateTimeRemaining, 1000);

        // Initial call to set the countdown immediately
        calculateTimeRemaining();

        return () => clearInterval(timer);
    }, [claimableETA]);

    return (
        <div className={styles.main}>
            <div className={styles.inner}>
                <div className={styles.progressDetailsContainer}>
                    <img src={zkGunLogo1} alt="zkGun Logo" className={styles.logo} />
                    <div className={styles.progressDetails}>
                        <div>
                            {address
                                ? <>{address?.slice(0, 5) + "..." + address?.slice(-5)}</>
                                : <></>
                            }
                        </div>
                        <div className={`${styles.progressContainer} + ${isLoading ? " " + styles.loading : ""}`}>
                            <div
                                className={styles.progress + " " + styles.claimable}
                                style={!isLoading ? { "width": `${(((claimable + withdrawn) / allocation) * 100)}%` } : { "width": `0%` }}
                            />
                            <div
                                className={styles.progress + " " + styles.claimed}
                                style={!isLoading ? { "width": `${((withdrawn / allocation) * 100)}%` } : { "width": `0%` }}
                            />
                        </div>
                        <div className={styles.claimDetails}>
                            <div className={styles.timer}>
                                <img src={timerIcon} alt="Timer" />
                                {timeRemaining || "00:00:00"}
                            </div>
                            <div>
                                <span className={styles.claimable}>{(claimable + withdrawn).toLocaleString()}</span> /
                                <span className={styles.allocation}> {allocation.toLocaleString()}</span>
                                <span className={styles.ticker}> $ZKGUN</span>
                            </div>
                        </div>
                    </div>
                </div>
                <VestingChart />
                <div className={styles.buttonContainer}>
                    {
                        !isConnected
                            ? <button
                                onClick={() => open()}
                                disabled={isLoading} >
                                Connect Wallet
                            </button>
                            : !isCorrectChain
                                ? <button
                                    onClick={() => switchToCorrectChain()}
                                    disabled={isLoading} >
                                    Switch Chain
                                </button>
                                : <button
                                    disabled={isLoading || withdrawn >= allocation || claimable == 0}
                                    onClick={() => claim()}
                                >
                                    Claim
                                </button>
                    }
                </div>
            </div>
        </div>
    );
}
