import { Exchange, Prices } from "../components";
import styles from '../styles/Pages/Home.module.scss'

export default function Home(): JSX.Element {
    return (
        <main className={styles.main}>
            <Prices />
            <Exchange />
        </main>
    );
}
