import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import round_arrow_icon from "../../assets/images/round_arrow_icon.png";
import {
    useCreateTransaction,
    useGetAvailablePairs,
    useGetEstimatedAmount,
    useGetMinAmount,
} from "../../hooks";
import styles from "../../styles/Exchange/Form.module.scss";
import { CurrenciesData, ExchangeFees, TransactionResponse } from "../../types";
import SearchableDropdown from "../SearchableDropdown";

export default function Form({
    setStep,
    setFees,
    transaction,
}: {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setFees: React.Dispatch<React.SetStateAction<ExchangeFees>>;
    transaction: UseQueryResult<AxiosResponse<TransactionResponse>, unknown>;
}): JSX.Element {
    const [from, setFrom] = useState<CurrenciesData | null>(null);
    const [to, setTo] = useState<CurrenciesData | null>(null);

    const [amount, setAmount] = useState<number | null>(null);
    const [address, setAddress] = useState<string | null>(null);

    const setSearchParams = useSearchParams()[1];


    const {
        data: pairsData,
        isLoading: isPairsLoading,
        isError: isPairsError,
    } = useGetAvailablePairs();

    const { refetch: refetchMinAmount, data: minAmount } = useGetMinAmount({
        fromCurrency: from?.ticker || "",
        toCurrency: to?.ticker || "",
        fromNetwork: from?.network || "",
        toNetwork: to?.network || "",
    });

    const { data: estimatedAmount, isLoading: isEstimateLoading } =
        useGetEstimatedAmount(
            {
                fromCurrency: from?.ticker || "",
                toCurrency: to?.ticker || "",
                fromNetwork: from?.network || "",
                toNetwork: to?.network || "",
                fromAmount: amount || 0,
            },
            !!from && !!to && !!amount
        );

    const {
        mutateAsync: createTransaction,
        isPending: isCreateTransactionLoading,
    } = useCreateTransaction();

    const handleCurrencySelect = (
        selectedCurrency: string,
        type: "from" | "to"
    ) => {
        const currency = pairsData?.data?.find(
            (pair) => pair.name === selectedCurrency
        );
        if (currency) {
            if (type === "from") setFrom(currency);
            else setTo(currency);
        } else {
            if (type === "from") setFrom(null);
            else setTo(null);
        }
    };

    const handleCurrencySwitch = () => {
        if (from && to) {
            setFrom(to);
            setTo(from);
        } else if (from) {
            setTo(from);
            setFrom(null);
        } else if (to) {
            setFrom(to);
            setTo(null);
        } else {
            toast.error("Select a currency");
        }
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amount = Number(e.target.value);
        if (amount < 0) {
            toast.error("Amount must be greater than 0");
            return;
        }
        setAmount(amount);
    };

    const handleCreateTransaction = async (
        e: React.FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            return;
        }
        if (!from || !to) {
            toast.error("Select a currency");
            return;
        }
        if (!amount) {
            toast.error("Enter an amount");
            return;
        }
        if (!address) {
            toast.error("Enter an address");
            return;
        }
        const minAmountData = await refetchMinAmount();
        const minAmount = minAmountData?.data?.data?.minAmount || 0;
        if (amount && amount >= minAmount) {
            try {
                const res = await createTransaction({
                    fromCurrency: from.ticker,
                    toCurrency: to.ticker,
                    fromNetwork: from.network,
                    toNetwork: to.network,
                    fromAmount: amount.toString(),
                    address,
                });
                if (res?.data?.id) {
                    window.navigator.clipboard.writeText(res.data.id);
                    toast.success("Transaction created");
                    setSearchParams({ id: res.data.id });
                    transaction.refetch();
                    setStep(3);
                }
            } catch (error: any) {
                if (error.response && error.response.data)
                    toast.error(error.response.data);
                else
                    toast.error("Error creating transaction");
            }
        } else {
            toast.error(`Amount must be greater than ${minAmount}`);
        }
    };

    useEffect(() => {
        if (from && to) {
            setStep(2);
        } else {
            setStep(1);
        }
    }, [from, to]);

    // Debounced Error Message
    const showError = useCallback(
        debounce((message) => {
            toast.error(message);
        }, 1000),
        []
    );

    useEffect(() => {
        if (estimatedAmount?.data && from && to) {
            setFees({
                withdrawalFee: estimatedAmount.data.withdrawalFee,
                depositFee: estimatedAmount.data.depositFee,
                fromAmount: estimatedAmount.data.fromAmount,
                toAmount: estimatedAmount.data.toAmount,
                fromCurrency: from.name,
                toCurrency: to.name,
                fromTicker: from.ticker,
                toTicker: to.ticker,
            });
        } else if (!amount) {
            setFees({
                withdrawalFee: 0,
                depositFee: 0,
                fromAmount: 0,
                toAmount: 0,
                fromCurrency: "",
                toCurrency: "",
                fromTicker: "",
                toTicker: "",
            });
        } else if (minAmount?.data && amount < minAmount?.data?.minAmount) {
            showError(
                `${from?.ticker.toUpperCase()} amount must be greater than ${minAmount?.data?.minAmount
                }`
            );
            setFees({
                withdrawalFee: 0,
                depositFee: 0,
                fromAmount: 0,
                toAmount: 0,
                fromCurrency: "",
                toCurrency: "",
                fromTicker: "",
                toTicker: "",
            });
        }
        return () => {
            showError.cancel();
        };
    }, [estimatedAmount, from, to, amount, minAmount?.data]);

    return (
        <form onSubmit={handleCreateTransaction} className={styles.form}>
            <div className={styles.sub__form}>
                {/* <label htmlFor="fromCurrency">You send</label> */}
                <div className={styles.form__input_group}>
                    <div className={styles.inputs}>
                        <div className={styles.dropdown}>
                            {from ? (
                                <img
                                    src={process.env.REACT_APP_API_URL + "image/" + from?.image}
                                    alt="from-asset"
                                    className={styles.icon}
                                />
                            ) : (
                                <></>
                            )}
                            <div className={styles.selection}>
                                <SearchableDropdown
                                    selectedVal={from?.ticker.toUpperCase() || ""}
                                    id="fromCurrency"
                                    label="name"
                                    handleChange={(value) => {
                                        handleCurrencySelect(value, "from");
                                    }}
                                    defaultToken="Ethereum"
                                    options={
                                        isPairsLoading
                                            ? [
                                                {
                                                    name: "Loading...",
                                                    disabled: true,
                                                },
                                            ]
                                            : isPairsError
                                                ? [
                                                    {
                                                        name: "Error",
                                                        disabled: true,
                                                    },
                                                ]
                                                : pairsData
                                                    ? pairsData.data
                                                        ?.filter(
                                                            (pair) =>
                                                                pair.name !== to?.name
                                                        )
                                                        .map((pair) => ({
                                                            name: pair.name,
                                                            image: pair.image,
                                                            disabled: false,
                                                        }))
                                                    : [
                                                        {
                                                            name: "No data",
                                                            disabled: true,
                                                        },
                                                    ]
                                    }
                                />
                            </div>
                        </div>
                        <div className={styles.inputVr} />
                        <input
                            className={styles.text}
                            type="number"
                            name="amount"
                            id="amount"
                            onChange={handleAmountChange}
                            value={
                                amount != undefined || amount != null
                                    ? amount
                                    : ""
                            }
                            placeholder="Enter amount"
                            required
                        />
                    </div>
                </div>
                <div className={styles.swapContainer}>
                    <button
                        type="button"
                        disabled={isPairsLoading}
                        onClick={handleCurrencySwitch}
                        className={styles.swap__button}
                    >
                        <img src={round_arrow_icon} alt="swap" />
                    </button>
                </div>
                <div className={styles.form__input_group}>
                    <div className={styles.inputs}>
                        <div className={styles.dropdown}>
                            {to ? (
                                <img
                                    src={process.env.REACT_APP_API_URL + "image/" + to?.image}
                                    alt="to-asset"
                                    className={styles.icon}
                                />
                            ) : (
                                <></>
                            )}
                            <div className={styles.selection}>
                                <SearchableDropdown
                                    selectedVal={to?.ticker.toUpperCase() || ""}
                                    id="toCurrency"
                                    label="name"
                                    handleChange={(value) => {
                                        handleCurrencySelect(value, "to");
                                    }}
                                    defaultToken="Solana"
                                    options={
                                        isPairsLoading
                                            ? [
                                                {
                                                    name: "Loading...",
                                                    disabled: true,
                                                },
                                            ]
                                            : isPairsError
                                                ? [
                                                    {
                                                        name: "Error",
                                                        disabled: true,
                                                    },
                                                ]
                                                : pairsData
                                                    ? pairsData.data
                                                        ?.filter(
                                                            (pair) =>
                                                                pair.name !==
                                                                from?.name
                                                        )
                                                        .map((pair) => ({
                                                            name: pair.name,
                                                            image: pair.image,
                                                            disabled: false,
                                                        }))
                                                    : [
                                                        {
                                                            name: "No data",
                                                            disabled: true,
                                                        },
                                                    ]
                                    }
                                />
                            </div>
                        </div>
                        <div className={styles.inputVr} />
                        <input
                            className={styles.text}
                            type="text"
                            readOnly
                            value={
                                isEstimateLoading
                                    ? "..."
                                    : estimatedAmount?.data?.toAmount
                                        ? `~ ${estimatedAmount?.data?.toAmount}`
                                        : "0"
                            }
                        />
                    </div>
                </div>
                {estimatedAmount?.data ? (
                    <div className={styles.fees}>
                        <div className={styles.fee__row}>
                            <p className={styles.title}>Deposit Fee</p>
                            <p className={styles.value}>
                                ~ {estimatedAmount?.data.depositFee}{" "}
                                {estimatedAmount?.data.fromCurrency}
                            </p>
                        </div>
                        <hr />
                        <div className={styles.fee__row}>
                            <p className={styles.title}>Withdraw Fee</p>
                            <p className={styles.value}>
                                ~ {estimatedAmount?.data.withdrawalFee}{" "}
                                {estimatedAmount?.data.toCurrency}
                            </p>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className={styles.input__box}>
                {/* <label htmlFor="address">Recipient Wallet</label> */}
                <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address || ""}
                    placeholder="Recipient Wallet Address"
                    required
                />
            </div>
            <button
                type="submit"
                className={styles.submit__button}
                disabled={isCreateTransactionLoading}
            >
                {isCreateTransactionLoading
                    ? "Loading..."
                    : "Proceed with deposit"}
            </button>
        </form>
    );
}
