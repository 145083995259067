import tick_icon from "../../assets/images/tick_icon.png";
import styles from "../../styles/Exchange/Steps.module.scss";

export default function Steps({ step }: { step: number }): JSX.Element {
    return (
        <section className={styles.steps}>
            <div
                className={`${styles.step} ${
                    step > 1
                        ? styles["step--done"]
                        : step === 1
                            ? styles["step--selected"]
                            : ""
                }`}
            >
                <div className={styles.step__number}>
                    {step > 1 ? <img src={tick_icon} alt="Tick" /> : "1"}
                </div>
                <div className={styles.step__content}>
                    <p className={styles.title}>Exchange Pair</p>
                    <p className={styles.description}>
                        Set the preferred exchange pair
                    </p>
                </div>
            </div>
            <div
                className={`${styles.step} ${
                    step > 2
                        ? styles["step--done"]
                        : step === 2
                            ? styles["step--selected"]
                            : ""
                }`}
            >
                <div className={styles.step__number}>
                    {step > 2 ? <img src={tick_icon} alt="Tick" /> : "2"}
                </div>
                <div className={styles.step__content}>
                    <p className={styles.title}>Wallet address</p>
                    <p className={styles.description}>
                        Fill in the wallet address details
                    </p>
                </div>
            </div>
            <div
                className={`${styles.step} ${
                    step > 3
                        ? styles["step--done"]
                        : step === 3
                            ? styles["step--selected"]
                            : ""
                }`}
            >
                <div className={styles.step__number}>
                    {step > 3 ? <img src={tick_icon} alt="Tick" /> : "3"}
                </div>
                <div className={styles.step__content}>
                    <p className={styles.title}>Deposit</p>
                    <p className={styles.description}>
                        Deposit the amount required for the exchange
                    </p>
                </div>
            </div>
            <div
                className={`${styles.step} ${
                    step >= 4
                        ? styles["step--done"]
                        : step === 4
                            ? styles["step--selected"]
                            : ""
                }`}
            >
                <div className={styles.step__number}>
                    {step >= 4 ? <img src={tick_icon} alt="Tick" /> : "4"}
                </div>
                <div className={styles.step__content}>
                    <p className={styles.title}>Completion</p>
                    <p className={styles.description}>Transaction successful</p>
                </div>
            </div>
            <div className={styles.steps__line}></div>
        </section>
    );
}
