import styles from "../../styles/Exchange/Fees.module.scss";
import { ExchangeFees } from "../../types";

export default function Fees({
    fees,
    isLoading,
}: {
    fees: ExchangeFees;
    isLoading: boolean;
}): JSX.Element {
    const {
        withdrawalFee,
        depositFee,
        fromAmount,
        toAmount,
        fromCurrency,
        toCurrency,
        fromTicker,
        toTicker,
    } = fees;

    return (
        <>
            {depositFee ? <h2>Transaction Details</h2> : <></>}
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {fromAmount ? (
                        <div className={styles.main__part}>
                            <p className={styles.title}>You Send</p>
                            <p className={styles.description}>
                                {fromAmount} <br />
                                {fromCurrency.split("(")[1]
                                    ? fromTicker.toUpperCase() +
                                      " (" +
                                      fromCurrency.split("(")[1]
                                    : fromTicker
                                        ? fromTicker
                                        : fromCurrency}
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className={styles.extra__parts}>
                        {depositFee ? (
                            <div className={styles.extra__part}>
                                <p className={styles.title}>Deposit Fee</p>
                                <p className={styles.description}>
                                    ~ {depositFee} {fromCurrency}
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                        {withdrawalFee ? (
                            <div className={styles.extra__part}>
                                <p className={styles.title}>Withdraw Fees</p>
                                <p className={styles.description}>
                                    ~ {withdrawalFee} {toCurrency}
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    {toAmount ? (
                        <div className={styles.main__part}>
                            <p className={styles.title}>You get</p>
                            <p className={styles.description}>
                                ~ {toAmount} <br />
                                {toCurrency.split("(")[1]
                                    ? toTicker.toUpperCase() +
                                      " (" +
                                      toCurrency.split("(")[1]
                                    : toTicker
                                        ? toTicker
                                        : toCurrency}
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
}
